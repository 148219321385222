import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
    },
    {
        path: '/',
        name: 'Layout',
        component: () => import('../components/Layout'),
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('../views/Home'),
                meta: {
                    title: '首页',
                    breadcrumb: [],
                }
            },
            // {
            //     path: 'questionnaire-management',
            //     name: 'QuestionnaireManagement',
            //     component: () => import('../views/QuestionnaireManagement'),
            //     meta: {
            //         title: '问卷管理',
            //         breadcrumb: [
            //             {label: '问卷管理', path: ''},
            //         ]
            //     }
            // },
            {
                path: 'activity-management',
                name: 'ActivityManagement',
                component: () => import('../views/ActivityManagement'),
                meta: {
                    title: '筛查活动',
                    breadcrumb: [
                        {label: '活动', path: '/activity-management'},
                        {label: '筛查活动', path: ''},
                    ]
                }
            },
            {
                path: 'live-management',
                name: 'LiveManagement',
                component: () => import('../views/LiveManagement'),
                meta: {
                    title: '直播活动',
                    breadcrumb: [
                        {label: '活动', path: '/activity-management'},
                        {label: '直播活动', path: ''},
                    ]
                }
            },
            {
                path: 'video-management',
                name: 'VideoManagement',
                component: () => import('../views/VideoManagement'),
                meta: {
                    title: '专栏',
                    breadcrumb: [
                        {label: '专栏', path: ''},
                    ]
                }
            },
            {
                path: 'community-management',
                name: 'CommunityManagement',
                component: () => import('../views/CommunityManagement'),
                meta: {
                    title: '社区',
                    breadcrumb: [
                        {label: '社区', path: ''},
                    ]
                }
            },
            {
                path: 'notice-management',
                name: 'NoticeManagement',
                component: () => import('../views/NoticeManagement'),
                meta: {
                    title: '消息',
                    breadcrumb: [
                        {label: '消息', path: ''},
                    ]
                }
            },
            {
                path: 'user-management',
                name: 'UserManagement',
                component: () => import('../views/UserManagement'),
                meta: {
                    title: '用户管理',
                    breadcrumb: [
                        {label: '用户管理', path: ''},
                    ]
                }
            },
            {
                path: 'commodity-management',
                name: 'CommodityManagement',
                component: () => import('../views/CommodityManagement'),
                meta: {
                    title: '商品管理',
                    breadcrumb: [
                        {label: '商品管理', path: ''},
                    ]
                }
            },
            {
                path: 'manual-management',
                name: 'ManualManagement',
                component: () => import('../views/ManualManagement'),
                meta: {
                    title: '手册管理',
                    breadcrumb: [
                        {label: '手册管理', path: ''},
                    ]
                }
            },
        ]
    }
]

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login') {
        if (store.state.USERINFO && store.state.USERINFO.username === 'admin' && store.state.USERINFO.password === 'admin') {
            next();
        } else {
            next('/login');
        }
    } else {
        next();
    }
});


export default router
