export const MIXIN_GLOBAL = {
    data() {
        return {
            _sizes: [10, 20, 50, 100],
            _total: 0,
            _q: {
                page: 1,
                size: 10,
            },
        }
    },
    computed: {
        dialogTitle() {
            switch (this.mode) {
                case 'add':
                    return '新增';
                case 'edit':
                    return '编辑';
                case 'view':
                case 'playback':
                    return '查看';
                case 'send':
                    return '发送';
            }
        }
    },
    methods: {
        _onSearch(callback) {
            this.$data._q.page = 1;
            callback && callback();
        },
        _reSearch(callback) {
            this.$data._q.page = 1;
            this.$data._q.size = 10;
            this.$data._total = 0;
            callback && callback();
        },
        _onSizeChange(size, callback) {
            this.$data._q.page = 1;
            this.$data._q.size = size;
            callback && callback();
        },
        _onCurrentChange(page, callback) {
            this.$data._q.page = page;
            callback && callback();
        },
        _confirm({msg, confirm, cancel}) {
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                confirm && confirm();
            }).catch(() => {
                cancel && cancel();
            });
        }
    }
}