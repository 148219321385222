import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 用户信息
        USERINFO: null,
    },
    getters: {},
    mutations: {
        // 保存用户信息
        setUserInfo(state, info) {
            state.USERINFO = info;
        }
    },
    actions: {},
    modules: {},
    plugins: [new VuexPersistence().plugin]
})
